<template>
  <div class="d-flex flex-wrap">
    <div class="w-100 d-flex justify-content-between flex-wrap">
      
      <b-form-group
        class="mb-1 ml-1"
      >
        <b-form-input
          v-model="amazonInvoice"
          style="width: 300px;"
          type="search"
          placeholder="Please Input Amazon Invoice Number"
          @input="changeAmazonInvoice"
        />
      </b-form-group>

      <!-- filter -->
      <b-form-group
        class="mb-1 mr-1"
      >
        <b-input-group size="md">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(show_details)="row">

        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
          {{ row.detailsShowing ? 'Hide' : 'Show' }}
        </b-form-checkbox>
      </template>

      <!-- full detail on click -->
      <template #row-details="row">
        <report-table-detail-view :items="row.item.detail" />
      </template>

      <!-- block_id -->
      <template #cell(block_id)="row">
        {{ row.item.short.block_id }}
      </template>

      <!-- trip_id -->
      <template #cell(trip_id)="row">
        {{ row.item.short.trip_id }}
      </template>

      <!-- estimated_cost -->
      <template #cell(estimated_cost)="row">
        <span v-if="row.item.short.estimated_cost">
          {{ row.item.short.estimated_cost.toFixed(2) }}$
        </span>
      </template>

      <!-- actual_cost -->
      <template #cell(actual_cost)="row">
        <span v-if="row.item.short.actual_cost">
          {{ row.item.short.actual_cost.toFixed(2) }}$
        </span>
      </template>

      <!-- difference -->
      <template #cell(difference)="row">
        <span v-if="row.item.short.difference">
          {{ row.item.short.difference.toFixed(2) }}$
        </span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BFormGroup, BFormSelect, BPagination, BCardBody, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { debounce } from 'debounce'
import ReportTableDetailView from './DetailView.vue'

export default {
  components: {
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ReportTableDetailView,
  },
  props: {
    items: {
      type: Array,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      perPage: 30,
      pageOptions: [30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        'show_details',
        { key: 'block_id', label: 'Block ID' },
        { key: 'trip_id', label: 'Trip ID' },
        { key: 'estimated_cost', label: 'Estimate Cost', variant: 'warning' },
        { key: 'actual_cost', label: 'Actual Cost', variant: 'success' },
        { key: 'difference', label: 'Difference', variant: 'danger' },
      ],
      amazonInvoice: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    changePage(e) {
      this.currentPage = e
      this.$emit('pageChange', { currentPage: e, perPage: this.perPage })
    },

    // eslint-disable-next-line func-names
    changeAmazonInvoice: debounce(function (ev) {
      this.$emit('changeAmazonInvoice', ev)
    }, 500),

  },
}
</script>
